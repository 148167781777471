import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { RelationshipService } from 'src/app/shared/map-data-services/relationship/relationship.service';
import { Actor } from 'src/app/shared/user/actor';
import { User, UserDTO } from 'src/app/shared/user/user';

import { PermissionType } from '../../map-data-services/mapWorkspace/permission';
import { TCObjectType } from '../files/TCFile.service';
import { GeoDataUpdateJob, GeoDataUpdateJobs, Task, TaskDTOStatus, TaskStatus, ToDoDTO } from './task';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    constructor(
        private http: HttpClient,
        private env: EnvironmentService,
        private relationshipService: RelationshipService
    ) {}

    async getTasksBySearch(
        projectId: string,
        workspaceId: string,
        status: TaskDTOStatus,
        searchInput: string = ''
    ): Promise<Task[]> {
        let taskPath =
            this.env.apiUrl +
            '/projects/' +
            projectId +
            '/dataupdatejobs/_search?workspaceId=' +
            workspaceId +
            '&status=' +
            status +
            '&pageSize=100';
        if (searchInput) {
            taskPath += '&title=' + searchInput;
        }
        const taskResponse = await lastValueFrom(this.http.get<GeoDataUpdateJobs>(taskPath));
        return taskResponse.items.map(item => Task.fromDTOandTodo(item, null));
    }

    async getTaskById(projectId: string, id: string): Promise<Task> {
        let taskPath = this.env.apiUrl + '/projects/' + projectId + '/dataupdatejobs' + '/' + id;

        const taskResponse = await lastValueFrom(this.http.get<GeoDataUpdateJob>(taskPath));
        const todoPath = this.env.connectApiUrl + '/todos' + '/' + taskResponse.todoId;
        const todoResponse = await lastValueFrom(
            this.http.get<ToDoDTO>(todoPath, { params: { projectId: projectId } })
        );
        let tmpTask = Task.fromDTOandTodo(taskResponse, todoResponse);
        return await this.checkTodoHasReturnJobUsers(projectId, tmpTask);
    }

    async checkTodoHasReturnJobUsers(projectId: string, tmpTask: Task): Promise<Task> {
        if (tmpTask.isTodoCompletedByUnassignedUser) {
            let promises: Promise<Partial<UserDTO>>[] = [];
            tmpTask.returnedJobs.forEach(job => {
                let path = this.env.apiUrl + '/projects/' + projectId + '/memberships';
                promises.push(lastValueFrom(this.http.get(path, { params: { userId: job.userId } })));
            });
            const responses = await Promise.all(promises);
            responses.forEach(response => {
                const tmpActor = Actor.fromDTO(response, PermissionType.USER);
                tmpActor.isTodoCompleted = true;
                tmpTask.unassignedCompletedUser.push(tmpActor);
            });
            return tmpTask;
        }
        return Promise.resolve(tmpTask);
    }

    async createOrUpdateTask(projectId: string, workspace: MapWorkspace, task: Task): Promise<Task> {
        let todoPath = this.env.connectApiUrl + '/todos';
        let taskPath = this.env.apiUrl + '/projects/' + projectId + '/dataupdatejobs';
        let dueDate = task.dueDate ? formatDate(task.dueDate, 'yyyy-MM-ddT23:59:59Z', 'en-US', null) : null;
        let todoObject = {
            description: task.description,
            projectId: projectId,
            priority: task.priority,
            status: TaskStatus.IN_PROGRESS,
            assignees: task.assignees,
            dueDate: dueDate,
            title: task.title,
            type: task.type ? task.type : 'Request'
        };
        let taskResponse: GeoDataUpdateJob;
        let todoResponse: ToDoDTO;
        if (task.id == null) {
            todoResponse = await lastValueFrom(this.http.post<ToDoDTO>(todoPath, todoObject));
            let todoId = todoResponse.id;
            let todoAttachmentsPath = this.env.connectApiUrl + '/todos/' + todoId + '/attachments';
            await lastValueFrom(
                this.http.post(todoAttachmentsPath, [
                    {
                        id: workspace.connectFileId,
                        type: TCObjectType.FILE
                    }
                ])
            );
            let taskData = {
                todoId,
                forms: task.features.map(taskFeature => ({
                    formId: taskFeature.featureId,
                    templateSeriesId: taskFeature.templateSeriesId,
                    status: taskFeature.status
                }))
            };
            taskResponse = await lastValueFrom(this.http.post<GeoDataUpdateJob>(taskPath, taskData));
            let relationshipProperties = {
                dataUpdateJobIds: [taskResponse.id]
            };
            await this.relationshipService.attachToMapWorkspace(projectId, workspace.id, relationshipProperties);
        } else {
            Object.assign(todoObject, { id: task.todoId });
            let taskData = task.toDTO();
            todoResponse = await lastValueFrom(this.http.patch<ToDoDTO>(todoPath + '/' + task.todoId, todoObject));
            taskResponse = await lastValueFrom(this.http.put<GeoDataUpdateJob>(taskPath + '/' + task.id, taskData));
        }
        return Task.fromDTOandTodo(taskResponse, todoResponse);
    }

    closeTask(projectId: string, user: User, task: Task): Promise<Task> {
        task.isClosed = true;
        let todoPath = this.env.connectApiUrl + '/todos';
        let taskPath = this.env.apiUrl + '/projects/' + projectId + '/dataupdatejobs';
        let taskData = task.toDTO();

        return lastValueFrom(this.http.put<GeoDataUpdateJob>(taskPath + '/' + task.id, taskData)).then(
            async taskResponse => {
                const todoResponse = await lastValueFrom(
                    this.http.patch<ToDoDTO>(todoPath + '/' + task.todoId, {
                        status: TaskStatus.CLOSED
                    })
                );
                return Task.fromDTOandTodo(taskResponse, todoResponse);
            },
            error => Promise.reject(error)
        );
    }

    reopenTask(projectId: string, task: Task): Promise<Task> {
        task.isClosed = false;
        let todoPath = this.env.connectApiUrl + '/todos';
        let taskPath = this.env.apiUrl + '/projects/' + projectId + '/dataupdatejobs';

        return lastValueFrom(this.http.delete(taskPath + '/' + task.id + '/returns')).then(
            async () => {
                const taskResponse = await lastValueFrom(this.http.get<GeoDataUpdateJob>(taskPath + '/' + task.id));
                const todoResponse = await lastValueFrom(
                    this.http.patch<ToDoDTO>(todoPath + '/' + task.todoId, {
                        status: TaskStatus.IN_PROGRESS
                    })
                );
                return Task.fromDTOandTodo(taskResponse, todoResponse);
            },
            error => Promise.reject(error)
        );
    }

    async deleteTask(projectId: string, task: Task): Promise<GeoDataUpdateJob> {
        task.isClosed = true;
        let todoPath = this.env.connectApiUrl + '/todos';
        let taskPath = this.env.apiUrl + '/projects/' + projectId + '/dataupdatejobs';
        const todoAndTask: [ToDoDTO, GeoDataUpdateJob] = await Promise.all([
            lastValueFrom(this.http.delete<ToDoDTO>(todoPath + '/' + task.todoId)),
            lastValueFrom(this.http.delete<GeoDataUpdateJob>(taskPath + '/' + task.id))
        ]);
        return todoAndTask[1];
    }
}
