import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { EnvironmentService } from '../environment/environment.service';
import { MapCache, MapCacheStyle } from './map-cache';

@Injectable({
    providedIn: 'root'
})
export class MapCacheService {
    constructor(private http: HttpClient, private env: EnvironmentService) {}

    public async getMapCacheList(projectId: string, workspaceId: string): Promise<MapCache[]> {
        const mapCachePath = '/projects/' + projectId + '/mapcaches';

        const options = workspaceId ? { params: { 'request.workspaceId': workspaceId } } : undefined;

        const response = await lastValueFrom(
            this.http.get<{ items: MapCache[] }>(this.env.apiUrl + mapCachePath, options)
        );
        return response.items;
    }

    public saveMapCacheList(
        projectId: string,
        name: string,
        polygonBounds: L.LatLngBounds,
        zoomLevel: string,
        baseMapStyle: MapCacheStyle
    ): Promise<MapCache> {
        let mapCachePath = '/projects/' + projectId + '/mapcaches';

        let mapCacheData = {
            geoMapCacheBody: {
                name: name,
                zoomlevel: zoomLevel,
                wgs84LatitudeNorth: polygonBounds.getNorth(),
                wgs84LatitudeSouth: polygonBounds.getSouth(),
                wgs84LongitudeWest: polygonBounds.getWest(),
                wgs84LongitudeEast: polygonBounds.getEast(),
                baseMapStyle: baseMapStyle
            }
        };
        return lastValueFrom(this.http.post<MapCache>(this.env.apiUrl + mapCachePath, mapCacheData));
    }

    public deleteMapCache(projectId: string, id: string): Promise<void> {
        let mapCachePath = '/projects/' + projectId + '/mapCaches/' + id;
        return lastValueFrom(this.http.delete<void>(this.env.apiUrl + mapCachePath));
    }

    public patchMapCache(projectId: string, id: string, mapCacheProperties: any): Promise<MapCache> {
        let mapCachePath = '/projects/' + projectId + '/mapCaches/' + id;
        return lastValueFrom(this.http.patch<MapCache>(this.env.apiUrl + mapCachePath, mapCacheProperties));
    }
}
