export const environment = {
    production: false,
    sendLogsToDatadog: true,
    enableRum: true,
    name: 'qa',
    connectMasterUrl: 'https://app.stage.connect.trimble.com',
    connectWebUrl: 'https://web.stage.connect.trimble.com',
    redirectURI: 'https://qa.maps.trimblegeospatial.com/app/oauth_redirect.html',
    consumerKey: 'a5f12a7c-dc7d-40ea-b854-6c9df0599c4e',
    mapContentMonitoringPeriodInSeconds: 30,
    identityConfigUrl: 'https://stage.id.trimblecloud.com/.well-known/openid-configuration',
    mapsApplicationName: 'MapsAPICloudSTAGE',
    featureFlagUrl: 'https://na.qa.api.maps.trimblegeospatial.com',
    processingOrchestratorApplicationName: 'TFProcessingOrchestrator-preprod',
    cbsListUrl: 'https://cbslistweb-api.t-iserv.com',
    mapTileUrl:
        'https://pcmiler.alk.com/apis/rest/v1.0/service.svc/maptile?X={x}&Y={y}&Z={z}&authtoken=EC2F7E6BBD22A443B7AFACE4461BD924'
};
