export enum ShareMode {
    PUBLIC_USER = 'PUBLIC',
    SIGNED_IN_USER = 'REGISTERED_USERS',
    PROJECT_USER = 'PROJECT_USERS',
    NONE = 'NONE'
}

export enum ShareTokenPermission {
    VIEW = 'VIEW',
    DOWNLOAD = 'DOWNLOAD'
}

export const ACCESS_MODE_KEY = 'accessMode';
export const SHARED_TOKEN_KEY = 'sharedToken';
export const SHARED_MODE_KEY = 'shareMode';

export enum AccessMode {
    BEARER = 'Bearer',
    SHARED = 'Shared'
}

export interface ShareToken {
    id: string;
    projectId: string;
    mode: ShareMode;
    permission: ShareTokenPermission;
    objects: {
        id: string;
        type: string;
        url: string;
        useLatestVersion: boolean;
    }[];
    shareToken: string;
    accessToken: string;
}
