import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng } from 'leaflet';
import { lastValueFrom } from 'rxjs';
import { ACCESS_MODE_KEY, AccessMode, SHARED_MODE_KEY, ShareMode } from 'src/app/core/authentication/share-token';
import { EnvironmentService } from 'src/app/shared/common/environment/environment.service';
import { TCFileDTO } from 'src/app/shared/common/files/TCFile';
import { User, UserRole } from 'src/app/shared/user/user';

import { ProjectService } from '../project/project.service';
import {
    ConnectFileError,
    CoordinateSystemComponent,
    CoordinateSystemComponentType,
    MapWorkspace
} from './map-workspace';
import { MapWorkspacePermission, MapWorkspacePermissionType } from './map-workspace-permission';
import {
    CoordinateSystemComponentDTO,
    DuplicateWorkspaceDTO,
    GeoWorkspace,
    GeoWorkspaces
} from './map-workspace.types';
import { PermissionDTO } from './permission';

export enum CSComponentScopes {
    GEOSPATIAL_MAPS = 'Geospatial_Maps',
    GEOSPATIAL_MAPSBETA = 'Geospatial_MapsBeta'
}
@Injectable({
    providedIn: 'root'
})
export class MapWorkspaceService {
    constructor(private http: HttpClient, private env: EnvironmentService, private projectService: ProjectService) {}

    private get connectApiUrl(): string {
        return this.env.connectApiUrl;
    }

    public getMapWorkspaces(projectId: string, loadedMapWorkspaces: MapWorkspace[]): Promise<MapWorkspace[]> {
        if (!loadedMapWorkspaces) {
            const workspacePath = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces';

            const mapWorkspaces: MapWorkspace[] = [];

            const loadMapWorkspaces = (pageIndex: number): Promise<MapWorkspace[]> => {
                const options = {
                    headers: {
                        Range: 'items=' + pageIndex + '-'
                    }
                };
                return lastValueFrom(this.http.get<GeoWorkspaces>(workspacePath, options)).then(
                    (workspacesDto: { items: any[]; total: number }) => {
                        mapWorkspaces.push(
                            ...workspacesDto.items.map(workspaceDto => MapWorkspace.fromDTO(workspaceDto, projectId))
                        );
                        if (mapWorkspaces.length < workspacesDto.total) {
                            // load workspaces from latest index
                            return loadMapWorkspaces(mapWorkspaces.length);
                        } else {
                            return mapWorkspaces;
                        }
                    }
                );
            };

            // load workspace from starting index
            return loadMapWorkspaces(0);
        } else {
            return Promise.resolve(loadedMapWorkspaces);
        }
    }

    public createMapWorkspace(userId: string, projectId: string, data: any): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces';
        return lastValueFrom(this.http.post<GeoWorkspace>(path, data)).then(
            workspaceDto => MapWorkspace.fromDTO(workspaceDto, projectId),
            error => Promise.reject(error)
        );
    }

    public createMapWorkspaceFromFile(projectId: string, data: any): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/ImportAsync';
        return lastValueFrom(this.http.post<GeoWorkspace>(path, data)).then(
            workspaceDto => MapWorkspace.fromDTO(workspaceDto, projectId),
            error => Promise.reject(error)
        );
    }

    public duplicateWorkspace(projectId: string, duplicateWorkspaceDTO: DuplicateWorkspaceDTO): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/DuplicateAsync';
        return lastValueFrom(this.http.post<GeoWorkspace>(path, duplicateWorkspaceDTO)).then(
            workspaceDto => MapWorkspace.fromDTO(workspaceDto, projectId),
            error => Promise.reject(error)
        );
    }

    public updateMapWorkspace(
        projectId: string,
        workspaceId: string,
        updatedWorkspace: MapWorkspace,
        updateWithStatus: boolean
    ): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/' + workspaceId;

        const updatedWorkspaceDTO = updateWithStatus ? updatedWorkspace.toDTOWithStatus() : updatedWorkspace.toDTO();

        return lastValueFrom(this.http.patch<GeoWorkspace>(path, updatedWorkspaceDTO)).then(workspaceDto =>
            MapWorkspace.fromDTO(workspaceDto, projectId)
        );
    }

    public editMapWorkspace(projectId: string, workspaceId: string, data: any): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/' + workspaceId;

        return lastValueFrom(this.http.put<GeoWorkspace>(path, data)).then(workspaceDto =>
            MapWorkspace.fromDTO(workspaceDto, projectId)
        );
    }

    public getMapWorkspaceById(projectId: string, workspaceId: string): Promise<MapWorkspace> {
        if (workspaceId) {
            const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/' + workspaceId;

            // Disable caching to fetch connectFileError incase of workspace file deletion in TC
            const options = {
                headers: {
                    'Cache-Control': 'no-store',
                    Pragma: 'no-cache'
                }
            };

            return lastValueFrom(this.http.get<GeoWorkspace>(path, options)).then(workspaceDto =>
                MapWorkspace.fromDTO(workspaceDto, projectId)
            );
        } else {
            return Promise.reject(null);
        }
    }

    public getFileViewerMW(projectId: string): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/viewer';
        return lastValueFrom(this.http.get<GeoWorkspace>(path)).then(workspaceDto =>
            MapWorkspace.fromDTO(workspaceDto, projectId)
        );
    }

    public getMapWorkspaceByConnectFileId(
        projectId: string,
        connectFileId: string,
        isShared = false
    ): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/connectFileId/' + connectFileId;

        return lastValueFrom(this.http.get<GeoWorkspace>(path)).then(
            workspaceDto => {
                if (isShared && workspaceDto.connectFileError === ConnectFileError.MAP_WORKSPACE_NOT_EXIST) {
                    workspaceDto.uuid = '55555555-5555-5555-5555-555555555555';
                    workspaceDto.connectFileError = ConnectFileError.PERMISSION_DENIED;
                }
                return MapWorkspace.fromDTO(workspaceDto, projectId);
            },
            (/*error*/) => {
                if (isShared) {
                    const workspaceDto = {
                        name: 'FILEVIEWER',
                        id: 'SHARED_WS_ID',
                        projectId: projectId,
                        connectFileId: connectFileId,
                        uuid: '55555555-5555-5555-5555-555555555555',
                        connectFileError: ConnectFileError.PERMISSION_DENIED
                    };
                    return MapWorkspace.fromDTO(workspaceDto, projectId);
                }
            }
        );
    }

    public deleteMapWorkspace(projectId: string, mapWorkspaceId: string): Promise<any> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/' + mapWorkspaceId;
        return lastValueFrom(this.http.delete(path));
    }

    public restoreMapWorkspace(projectId: string, mapWorkspaceId: string): Promise<MapWorkspace> {
        const path = this.env.apiUrl + '/projects/' + projectId + '/spatialworkspaces/' + mapWorkspaceId + '/_restore';
        return lastValueFrom(this.http.post<GeoWorkspace>(path, {})).then(workspaceDto =>
            MapWorkspace.fromDTO(workspaceDto, projectId)
        );
    }

    public updateWorkspaceImage(workspace: MapWorkspace, file?: File, areaImage: boolean = false): Promise<object> {
        if (!workspace) {
            return Promise.reject('Workspace is required');
        }

        let path = `${this.env.apiUrl}/projects/${workspace.projectId}/spatialworkspaces/${
            workspace?.id
        }/image?uploadWorkspaceAreaImage=${areaImage.toString()}`;

        let formData = null;

        if (file) {
            formData = new FormData();
            formData.append('file', file, file.name);
        }

        return lastValueFrom(this.http.put(path, formData));
    }

    // --------------------

    public getMapWorkspacePermission(
        projectId: string,
        mapWorkspace: MapWorkspace,
        user: User
    ): Promise<MapWorkspacePermission> {
        if (mapWorkspace.multipleFileView) {
            return this.getMultipleMapWorkspacePermission(projectId, mapWorkspace, user);
        } else {
            const connectFileOrVersionId =
                mapWorkspace.isFileViewer &&
                mapWorkspace.connectFileVersionIds &&
                mapWorkspace.connectFileVersionIds.length > 0
                    ? mapWorkspace.connectFileVersionIds[0]
                    : mapWorkspace.connectFileId;

            return this.getSingleMapWorkspacePermission(projectId, mapWorkspace, connectFileOrVersionId, user);
        }
    }

    private getMultipleMapWorkspacePermission(
        projectId: string,
        mapWorkspace: MapWorkspace,
        user: User
    ): Promise<MapWorkspacePermission> {
        let multipleMapWorkspacePermission: MapWorkspacePermission = new MapWorkspacePermission(
            mapWorkspace.id,
            user.id
        );
        multipleMapWorkspacePermission.effectivePermission = MapWorkspacePermissionType.FULL_ACCESS;

        const promises = (mapWorkspace.connectFileVersionIds as any[]).map(connectFileOrVersionId =>
            this.getSingleMapWorkspacePermission(projectId, mapWorkspace, connectFileOrVersionId, user)
        );

        return Promise.all(promises).then(mapWorkspacePermissions => {
            mapWorkspacePermissions.forEach((mapWorkspacePermission, index) => {
                multipleMapWorkspacePermission.fileName +=
                    index === mapWorkspacePermissions.length - 1
                        ? mapWorkspacePermission.fileName
                        : mapWorkspacePermission.fileName + ' , ';
                multipleMapWorkspacePermission.folderId =
                    mapWorkspacePermission.folderId === null || multipleMapWorkspacePermission.folderId === null
                        ? null
                        : mapWorkspacePermission.folderId;
                multipleMapWorkspacePermission.folderIds.push(mapWorkspacePermission.folderId);
                multipleMapWorkspacePermission.fileIds = multipleMapWorkspacePermission.fileIds.concat(
                    mapWorkspacePermission.fileIds
                );
                multipleMapWorkspacePermission.fileVersionIds = multipleMapWorkspacePermission.fileVersionIds.concat(
                    mapWorkspacePermission.fileVersionIds
                );
                multipleMapWorkspacePermission.fileLastAssimIds =
                    multipleMapWorkspacePermission.fileLastAssimIds.concat(mapWorkspacePermission.fileLastAssimIds);
                multipleMapWorkspacePermission.effectivePermission =
                    mapWorkspacePermission.effectivePermission === MapWorkspacePermissionType.NO_ACCESS
                        ? MapWorkspacePermissionType.NO_ACCESS
                        : multipleMapWorkspacePermission.effectivePermission;
            });
            return multipleMapWorkspacePermission;
        });
    }

    private getSingleMapWorkspacePermission(
        projectId: string,
        mapWorkspace: MapWorkspace,
        connectFileOrVersionId: string,
        user: User
    ): Promise<MapWorkspacePermission> {
        return new Promise((resolve, reject) => {
            // -----------------------------------
            // Shared mode: returns either 'FILE_VIEW_MODE' or 'NO_ACCESS'
            // -----------------------------------
            if (
                sessionStorage.getItem(ACCESS_MODE_KEY) &&
                sessionStorage.getItem(ACCESS_MODE_KEY) === AccessMode.SHARED
            ) {
                // first check the file to determine if we have access or not
                const filePath = this.connectApiUrl + '/files/' + connectFileOrVersionId;
                lastValueFrom(this.http.get(filePath)).then(
                    (fileResponse: any) => {
                        // have access to file...
                        let mapWorkspacepermission: MapWorkspacePermission = new MapWorkspacePermission(
                            mapWorkspace.id,
                            user.id
                        );

                        mapWorkspacepermission.folderId = fileResponse.parentId;
                        mapWorkspacepermission.fileIds = [fileResponse.id];
                        mapWorkspacepermission.fileName = fileResponse.name;
                        mapWorkspacepermission.fileVersionIds = [fileResponse.id];
                        mapWorkspacepermission.fileLastAssimIds = [fileResponse.id];
                        // Added to handle shareMode changes in TC
                        // We are defaulting all Project users to full access and all other modes to read access.
                        // All other resources except workspaces are defaulted to file view mode.
                        mapWorkspacepermission.effectivePermission = MapWorkspacePermissionType.FILE_VIEW_MODE;
                        return resolve(mapWorkspacepermission);
                    },
                    () => {
                        // no access to file...
                        let mapWorkspacepermission: MapWorkspacePermission = new MapWorkspacePermission(
                            mapWorkspace.id,
                            user.id
                        );
                        mapWorkspacepermission.effectivePermission = MapWorkspacePermissionType.NO_ACCESS;
                        return resolve(mapWorkspacepermission);
                    }
                );
            } else {
                // -----------------------------------
                // Normal mode: returns either 'FULL_ACCESS', 'READ', or 'NO_ACCESS'
                // -----------------------------------

                // first check the file to determine if we have access or not
                const filePath = this.connectApiUrl + '/files/' + connectFileOrVersionId;
                lastValueFrom(this.http.get(filePath)).then(
                    (fileResponse: any) => {
                        // have access to file..., but is it 'FULL_ACCESS' or only 'READ'?
                        if (fileResponse.parentId) {
                            // check the immediate parent folder to determine this (Note: should really check all
                            // ancestor folders but Connect API actually only enforces based on the immediate parent!)
                            const folderPermissionPath =
                                this.connectApiUrl + '/folders/' + fileResponse.parentId + '/permissions';

                            lastValueFrom(this.http.get(folderPermissionPath)).then(
                                async (folderPermissionsResponse: PermissionDTO) => {
                                    const permission = await this.projectService.getUserOrGroupPermissions(
                                        folderPermissionsResponse,
                                        user.id
                                    );
                                    let mapWorkspacePermission = new MapWorkspacePermission(mapWorkspace.id, user.id);
                                    mapWorkspacePermission.folderId = fileResponse.parentId;
                                    mapWorkspacePermission.fileIds = [fileResponse.id];
                                    mapWorkspacePermission.fileName = fileResponse.name;
                                    mapWorkspacePermission.fileVersionIds = [fileResponse.versionId];
                                    mapWorkspacePermission.fileLastAssimIds = [fileResponse.id];
                                    mapWorkspacePermission.effectivePermission = permission.effectivePermission;

                                    // Added to bypass default permissions for the workspace when opened in shared mode
                                    if (
                                        sessionStorage.getItem(SHARED_MODE_KEY) === ShareMode.PROJECT_USER &&
                                        sessionStorage.getItem('sharedFileId') === fileResponse.id
                                    ) {
                                        mapWorkspacePermission.effectivePermission =
                                            MapWorkspacePermissionType.FULL_ACCESS;
                                    }

                                    return resolve(mapWorkspacePermission);
                                }
                            );
                        } else {
                            // no parent folder...
                            let mapWorkspacePermission: MapWorkspacePermission = new MapWorkspacePermission(
                                mapWorkspace.id,
                                user.id
                            );
                            // TODO: BSJ - why?
                            mapWorkspacePermission.effectivePermission =
                                user.role === UserRole.ADMIN
                                    ? MapWorkspacePermissionType.READ
                                    : MapWorkspacePermissionType.NO_ACCESS;
                            return resolve(mapWorkspacePermission);
                        }
                    },
                    () => {
                        // no access to file...
                        let mapWorkspacePermission: MapWorkspacePermission = new MapWorkspacePermission(
                            mapWorkspace.id,
                            user.id
                        );
                        // TODO: BSJ - why?
                        mapWorkspacePermission.effectivePermission =
                            user.role === UserRole.ADMIN
                                ? MapWorkspacePermissionType.READ
                                : MapWorkspacePermissionType.NO_ACCESS;
                        return resolve(mapWorkspacePermission);
                    }
                );
            }
        });
    }

    public getTCFile(fileId: string): Promise<TCFileDTO> {
        const path = this.connectApiUrl + '/files/' + fileId;

        return lastValueFrom(this.http.get<TCFileDTO>(path)).then(
            response => response,
            () => null
        );
    }

    public getTCFilePermission(fileId: string, versionId: string): Promise<string> {
        const tcFilePath = this.connectApiUrl + '/files/' + fileId + '?tokenThumburl=false&versionId=' + versionId;

        return lastValueFrom(this.http.get<TCFileDTO>(tcFilePath)).then(
            tcFileResponse =>
                // For shared project users workflow, file api return response with permission as no access
                tcFileResponse.permission === MapWorkspacePermissionType.NO_ACCESS
                    ? MapWorkspacePermissionType.READ
                    : tcFileResponse.permission,
            () => MapWorkspacePermissionType.NO_ACCESS
        );
    }

    public getDatumsListByLocation(location: LatLng): Promise<CoordinateSystemComponent[]> {
        const datumListPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=Datum&componentScope=Geospatial_Maps&componentStatus=Released' +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(datumListPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getZoneGroupsByLocation(location: LatLng): Promise<CoordinateSystemComponent[]> {
        const zoneGroupsPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=ZoneGroup&componentScope=Geospatial_Maps&componentStatus=Released' +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(zoneGroupsPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getZonesByLocationAndParentId(
        location: LatLng,
        zoneGroupComponentId: string
    ): Promise<CoordinateSystemComponent[]> {
        const zonesPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=Zone&componentScope=Geospatial_Maps&componentStatus=Released' +
            '&parentComponentID=' +
            zoneGroupComponentId +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(zonesPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getZonesByLocation(location: LatLng): Promise<CoordinateSystemComponent[]> {
        const zonesPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=Zone&componentScope=Geospatial_Maps&componentStatus=Released&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(zonesPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getGeoidsByLocation(location: LatLng): Promise<CoordinateSystemComponent[]> {
        const geoidsPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=Geoid&componentScope=Geospatial_Maps&componentStatus=Released' +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(geoidsPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getTectonicPlatesByLocation(location: LatLng): Promise<CoordinateSystemComponent[]> {
        const geoidsPath =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentType=TectonicPlate&componentScope=Geospatial_Maps&componentStatus=Released' +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(geoidsPath)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getComponentById(
        componentType: CoordinateSystemComponentType,
        id: string
    ): Promise<CoordinateSystemComponent> {
        const path =
            this.env.coordinatesApiUrl + '/coordinatesystemcomponents/' + id + '?componentType=' + componentType;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO>(path)).then(response =>
            CoordinateSystemComponent.fromDTO(response)
        );
    }

    public getComponents(
        location: LatLng,
        componentScope = CSComponentScopes.GEOSPATIAL_MAPS
    ): Promise<CoordinateSystemComponent[]> {
        const path =
            this.env.coordinatesApiUrl +
            '/coordinatesystemcomponents' +
            '?componentScope=' +
            componentScope +
            '&componentStatus=Released' +
            '&proximityLatitude=' +
            location.lat +
            '&proximityLongitude=' +
            location.lng;

        return lastValueFrom(this.http.get<CoordinateSystemComponentDTO[]>(path)).then(response =>
            response.map(item => CoordinateSystemComponent.fromDTO(item))
        );
    }

    public getFeatureTagsByLayers(projectId: string, layerIds: string[]): Promise<string[]> {
        // Get available custom feature tags within the current workspace, each time the workspace is loaded
        const filter = encodeURIComponent(
            JSON.stringify([
                {
                    operator: 'EQ',
                    field: 'metadata.common_layerId',
                    operand: layerIds
                }
            ])
        );
        const path = `${this.env.featureApiUrl}/projects/${projectId}/features/aggregations?field=tags&aggregation=Distinct&filter=${filter}&type=Text`;

        return lastValueFrom(this.http.get<string[]>(path));
    }
}
